import { dayjs } from '../date';
import Base, {
  ModelAPIIndexFieldParam,
  ModelAPIIndexMeta,
  ModelAPIIndexParams,
} from './Base';

export interface ArticleAttrs {
  id: number;
  title: string;
  slug: string;
  image: string;
  imageAlt: string;
  content: string;
  description: string;
  metaTitle?: string;
  metaDescription?: string;
  priority: number;
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  likeCount?: number;
  personLike?: boolean;
  earnedCoin?: number;
}

export default class Article extends Base<ArticleAttrs> {
  static async fetch(
    params?: Partial<Record<keyof ArticleAttrs, ModelAPIIndexFieldParam>> &
      ModelAPIIndexParams
  ) {
    const response = await this.$api.get<{
      data: ArticleAttrs[];
      meta: ModelAPIIndexMeta;
    }>('v1/articles', { params });
    const data = response.data.data;
    const meta = response.data.meta;

    return { data, meta };
  }

  static async fetchBySlug(slug: string) {
    const { data } = await this.fetch({ slug });
    return data[0];
  }

  get routePath() {
    return `/articles/${this.data.slug}`;
  }

  get publishedAt() {
    return dayjs(this.data.publishedAt);
  }

  getThumbnailURL(width: number, height?: number) {
    if (!this.data.image) {
      const $img = useImage();
      return $img('/assets/img/articles/image.jpg', { width, height });
    }

    return imageUtil.getThumbnailURL(this.data.image, width, height);
  }

  async like() {
    const response = await Article.$api.post(
      `/v1/articles/${this.data.id}/like`
    );
    this.data.likeCount++;
    this.data.personLike = true;
    return response;
  }

  async read() {
    const response = await Article.$api.post(
      `/v1/articles/${this.data.slug}/read`
    );
    return response.data.data;
  }

  async share() {
    const response = await Article.$api.post(
      `/v1/articles/${this.data.id}/share`
    );
    return response.data.data;
  }
}
